import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  CardList,
  Column,
  Container,
  Heading,
  Head,
  Emphasized,
  Icon,
  Paragraph,
  Row,
  SecondarySection,
  Section,
  Spacer,
  ImageLayer,
  Subtitle,
} from "../../components/components"
import CallToAction from "../../widgets/CallToAction/CallToAction"
import Method from "../../widgets/Method/Method"
import Sectors from "../../widgets/Sectors/Sectors"

const Industries = ({}: { data: any }) => {
  const data = useStaticQuery(query)
  return (
    <>
      <Head pagePath="/branchen" />

      <Section>
        <Container>
          <Row>
            <Column style={{ alignItems: "center", padding: "0 24px" }}>
              <Heading tag="h1" style={{ textAlign: "center" }}>
                Branchen, denen wir bei der
                <Emphasized> Digitalisierung </Emphasized>helfen
              </Heading>
            </Column>
          </Row>
          <Spacer gutter={16} />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Paragraph textAlign="center">
              Über die Jahre haben wir für zahlreiche Unternehmen aus den
              folgenden Branchen bereits Projekte erfolgreich umsetzen dürfen.
              Daraus ergibt sich unsere Erfahrung und Kompetenz, spezifische als
              auch teilweise branchenübergreifende Herausforderungen schnell zu
              identifizieren und gekonnt zu lösen.
            </Paragraph>
          </div>
        </Container>
      </Section>
      <SecondarySection>
        <Spacer gutter={40} />
        <Container>
          <Row style={{ margin: 0 }}>
            <Column style={{ padding: "0 24px" }}>
              <ImageLayer
                color="purple"
                image={data.ourValues.image}
                alt="Programmierer schaut auf Monitor mit Quellcode. Eine Kollege zeigt auf den Monitor."
              >
                <Subtitle color="purple">BRANCHENKOMPETENZ</Subtitle>
                <Heading tag="h1">Was uns auszeichnet</Heading>
                <Paragraph>
                  Softwareentwicklung und Prozessoptimierung sind Themen, die in
                  nahezu jeder Branche von Bedeutung sind. Kompetenz heißt aber
                  auch, seine eigenen Kompetenzen zu kennen. Um unseren eigenen
                  hohen Qualitätsstandards gerecht werden zu können,
                  konzentrieren wir unsere Kompetenzen auf Branchen, in denen
                  wir hochwertige Dienstleistungen und einen bedeutenden
                  Mehrwert aus unserer Arbeit gewährleisten können. Aus diesem
                  Grund haben wir hier Branchen aufgeführt, in denen wir mit
                  ausgezeichneter Expertise glänzen können. Diese Ausführungen
                  dienen als Orientierung, um Ihnen einen Überblick über unsere
                  Schlüsselkompetenzen und Erfahrungen zu gewähren. Sollte sich
                  Ihr Betätigungsfeld außerhalb dieser Branchen befinden,
                  ermitteln wir gerne in einem persönlichen Gespräch Ihre
                  Bedürfnisse und ob wir über die Kompetenzen, Kapazitäten und
                  Ressourcen verfügen, diese zu erfüllen.
                </Paragraph>
              </ImageLayer>
            </Column>
          </Row>
        </Container>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Column>
            <Sectors />
          </Column>
        </Row>
        <Container style={{ marginTop: "3rem" }}>
          <Row>
            <Column style={{ padding: "0 24px" }}>
              <Heading tag="h1">Vorteile der Digitalisierung</Heading>
              <Paragraph>
                Die Digitalisierung von Unternehmen ist in aller Munde. Wer
                immer noch auf undurchsichtige Excel Tabellen und
                Papier-Formulare für interne Geschäftsprozesse setzt,
                verschwendet häufig Ressourcen und produziert damit nicht nur
                Kosten, sondern Fehler. Mit individueller Software vermeiden Sie
                diese Probleme, optimieren Ihre Geschäftsprozesse und stellen
                Ihr Unternehmen für die Zukunft sicher auf.
              </Paragraph>
            </Column>
          </Row>
        </Container>

        <Container>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Column style={{ padding: "0 24px" }}>
              <CardList
                entries={[
                  {
                    icon: () => <ReductionIcon />,
                    title: "Kosten senken",
                    color: "blue",
                    description:
                      "Obwohl die Entwicklung maßgeschneiderter Software mit einer einmaligen Investition verbunden ist, amortisiert sich der Kostenfaktor in der Regel nach kurzer Zeit. Durch eine effiziente Softwarelösung machen sich erreichte Einsparungen an Zeit und Personal sowie Fehlerreduktionen schnell bezahlt.",
                  },
                  {
                    icon: () => <EfficientIcon />,
                    title: "Effizienz erhöhen",
                    color: "red",
                    description:
                      "Wir entwickeln individuelle und intuitive Software, die Ihre Unternehmensprozesse einfacher, strukturierter und effizienter gestaltet. Zudem wird die Einarbeitung neuer Mitarbeiter durch die Nutzung intuitiver Software verkürzt, während Vorgänge automatisiert und beschleunigt werden.",
                  },
                  {
                    icon: () => <BenefitsIcon />,
                    title: "Umsatz steigern",
                    color: "purple",
                    description:
                      "Die Entwicklung von digitalen Geschäftsmodellen eröffnet Unternehmen neue Möglichkeiten, Umsatzchancen zu realisieren und mit ihren Kunden zu interagieren. Nutzen auch Sie die Potenziale digitaler Geschäftsmodelle und stellen Sie die Weichen für das zunehmend digitale Zeitalter.",
                  },
                ]}
              />
            </Column>
          </Row>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Column>
              <Method />
            </Column>
          </Row>
        </Container>
      </SecondarySection>
      <CallToAction type="customer" />
    </>
  )
}

export default Industries

const BenefitsIcon = styled(Icon.Benefits)`
  width: 24px;
  height: 24px;
`
const ReductionIcon = styled(Icon.Reduction)`
  width: 24px;
  height: 24px;
`
const EfficientIcon = styled(Icon.Efficient)`
  width: 24px;
  height: 24px;
`
export const query = graphql`
  query {
    ourValues: file(relativePath: { eq: "branches/wasUnsAuszeichnet.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
