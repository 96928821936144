import React from "react"
import styled from "styled-components"
import {
  Container,
  Heading,
  Icon,
  IconContainer,
  Paragraph,
} from "../../components/components"
import FlexComponent from "../../components/Flex/Flex"

const Sectors = () => {
  return (
    <Container>
      <Flex>
        <IndustryItem>
          <IconContainer>
            <HospitalIcon fill="#f53b57" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              HealthCare
            </Heading>
            <Paragraph>
              Im Rahmen der Digitalisierung des Gesundheitswesens entwickeln wir
              innovative Technologielösungen, um in den Bereichen digitaler
              Gesundheit, verbesserter Patientenerfahrung und effizienter
              Ressourcen-Planung unseren Beitrag zu leisten.
            </Paragraph>
          </Paper>
        </IndustryItem>

        <IndustryItem>
          <IconContainer>
            <CarIcon fill="#F9690E" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              Automotive
            </Heading>
            <Paragraph>
              Unseren Kunden aus der Automobilbranche helfen wir dabei, durch
              die Entwicklung innovativer B2C- und B2B-Plattformen, neue
              Möglichkeiten digitaler Technologien auszuschöpfen, um Prozesse zu
              optimieren und die Kundenorientierung zu stärken.
            </Paragraph>
          </Paper>
        </IndustryItem>

        <IndustryItem>
          <IconContainer>
            <FactoryIcon fill="#9B59B6" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              Fertigung
            </Heading>
            <Paragraph>
              Von uns entwickelte Lösungen für die Fertigung sind darauf
              ausgelegt, die Abläufe unserer Kunden vom Qualitätsmanagement bis
              zum Lieferkettenmanagement zu rationalisieren, um eine Optimierung
              der Produktivität und eine Reduzierung der Betriebskosten zu
              erreichen
            </Paragraph>
          </Paper>
        </IndustryItem>

        <IndustryItem>
          <IconContainer>
            <DesignConceptIcon fill="#2188F9" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              Unterhaltung
            </Heading>
            <Paragraph>
              Innerhalb der Unterhaltungsindustrie entwickeln wir
              maßgeschneiderte Software-Lösungen für unsere Kunden. Eine
              individualisierte Plattform zur Steuerung von VR/AR Gadgets sowie
              Web-Apps zum Management von Musikkünstlern sind nur einzelne
              Beispiele unserer Arbeit.
            </Paragraph>
          </Paper>
        </IndustryItem>

        <IndustryItem>
          <IconContainer>
            <DiningIcon fill="#03C9A9" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              Catering
            </Heading>
            <Paragraph>
              Für unsere Kunden aus der Catering- und Lebensmittelindustrie
              entwickeln wir individuelle Menübestellsysteme. Privatpersonen,
              Schulen und KITAs können durch die Anbindung der Online-Shops an
              bestehende Menüplanungssysteme einfach Bestellungen aufgeben.
            </Paragraph>
          </Paper>
        </IndustryItem>

        <IndustryItem>
          <IconContainer>
            <ElectronicsIcon fill="#575fcf" />
          </IconContainer>
          <Paper>
            <Heading tag="h4" style={{ paddingTop: "16px" }}>
              IT
            </Heading>
            <Paragraph>
              Wir helfen Technologieunternehmen bei der Innovation und
              Transformation Ihrer Geschäftsmodelle durch die Entwicklung neuer
              Digitalprodukte, Cloud Services, IoT-Plattformen als auch Themen
              wie Big Data und Machine Learning.
            </Paragraph>
          </Paper>
        </IndustryItem>
      </Flex>
    </Container>
  )
}

export default Sectors

const Flex = styled(FlexComponent)`
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;

  > * {
    max-width: 48%;

    @media only screen and (max-width: 850px) {
      max-width: 100%;
    }
  }
`

const IndustryItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 1 auto;
  padding: 24px;
  background: ${props => props.theme.palette.foregroundColor};
  flex-wrap: wrap;
  border-radius: ${props => props.theme.borderRadius};
  margin-top: 2rem;
`

const CarIcon = styled(Icon.Car)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`
const FactoryIcon = styled(Icon.Factory)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`
const DiningIcon = styled(Icon.Dining)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`
const HospitalIcon = styled(Icon.Hospital)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`
const ElectronicsIcon = styled(Icon.Electronics)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`

const DesignConceptIcon = styled(Icon.DesignConcept)`
  width: 32px;
  path {
    fill: ${props => props.fill};
  }
`

const Paper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`
